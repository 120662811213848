import McMiningCompanyService from '@/services/api/MiningCompany/McMiningCompany'
// import RulesService from '@/services/rules'

export default {
  name: 'v-view-mining-company-profile',
  data() {
    return {
      busy: {
        company: false,
      },
      dialogs: {
        image: false,
      },
      mcMiningCompany: {
        image: '',
        imgSrc: '',
      },
    }
  },
  methods: {

    /**
     * 
     */
    async getMiningCompany() {
      this.busy.company = true
      this.mcMiningCompany = await McMiningCompanyService.getById(this.$user.profile.relatedId)
      this.busy.company = false
    },

    /**
     * 
     */
    handleImageError(payload) {
      McMiningCompanyService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleImageReset() {
      this.mcMiningCompany.image = ''
    },

    /**
     * 
     */
    async handleImageSubmit() {
      this.busy.company = true
      await McMiningCompanyService.logo(this.mcMiningCompany).then(this.handleImageSuccess).then(this.getMiningCompany).catch(this.handleImageError)
      this.busy.company = false
    },

    /**
     * 
     */
    handleImageSuccess(response) {
      McMiningCompanyService.translate({ response }).then(this.$toast.success)
    },
  },
  mounted() {
    this.getMiningCompany()
  },
}